import React, { useState, useEffect, useContext } from 'react';
import { Grid, Button, Typography, Dialog, useMediaQuery, IconButton, Select, MenuItem } from "@mui/material";
import { GridToolbarContainer, GridToolbarExport, GridToolbarFilterButton } from "@mui/x-data-grid";
import { esES } from "@mui/x-data-grid";
import CustomDataGrid from "../../../Components/ABM/MUIComponents/CustomDataGrid";
import ModalDescuentos from './ModalDescuentos';
import DeleteIcon from '@mui/icons-material/Delete';
import useFetch, { host } from '../../../Utils/Fetchs';
import BotonLoading from '../../../Components/ABM/MUIComponents/BotonLoading';

export default function Descuentos() {
    const [descuentos, setDescuentos] = useState([]);
    const [descuentoSeleccionado, setDescuentoSeleccionado] = useState('');
    const [openDialog, setOpenDialog] = useState(false);
    const [openModal, setOpenModal] = useState(false);
    const isMobile = useMediaQuery('(max-width:600px)');
    const [loading, setLoading] = useState(false);

    const { getFetch, deleteFetch } = useFetch();

    useEffect(() => {
        getFetch(`${host}descuentos/`, true)
            .then(data => {
                setDescuentos(data.descuentos); console.log(data.descuentos);
            })
            .catch(err => console.error(err));
    }, []);

    function handleOpenModal(descuentoSeleccionado) {
        if (openModal) {
            setDescuentoSeleccionado(null);
            setOpenModal(false);
        } else {
            if (descuentoSeleccionado) setDescuentoSeleccionado(descuentoSeleccionado);
            setOpenModal(true);
        }
    }

    function handleOpenDialogEliminar(row) {
        if (row) {
            setOpenDialog({
                open: true,
                action: 'eliminar',
                handleAction: () => {
                    setLoading(true);
                    deleteFetch(`${host}descuentos/`, { id: row.id }, true)
                        .then(() => {
                            setDescuentos(descuentos.filter(descuento => descuento.id !== row.id));
                        })
                        .finally(() => {
                            setOpenDialog({ open: false, action: '', handleAction: null });
                            setLoading(false);
                        });
                }
            });
        }
    }

    function handleCloseDialog(shouldExecuteAction) {
        if (shouldExecuteAction && openDialog.handleAction) {
            openDialog.handleAction();
        } else {
            setOpenDialog({ open: false });
        }
    }

    function CustomGridToolBar() {
        return (
            <GridToolbarContainer style={{ display: 'flex', alignItems: 'center', justifyContent: isMobile ? 'center' : 'space-between' }}>
                <Button variant="contained" color="primary" onClick={() => handleOpenModal()}>
                    Crear Descuentos
                </Button>
                {!isMobile && (
                    <>
                        <GridToolbarExport />
                    </>
                )}
            </GridToolbarContainer>
        );
    }

    const columns = [
        { field: "nombre", headerName: "Nombre", flex: 1, minWidth: 120 },
        { field: "cantidad_minima", headerName: "Cantidad Mínima", flex: 1, minWidth: 120 },
        { field: "porcentaje", headerName: "Porcentaje", flex: 1, minWidth: 120, renderCell: (params) => `${params.value}%` },
        { field: "descripcion", headerName: "Descripción", flex: 1.5, minWidth: 200 },
        {
            field: "categorias",
            headerName: "Categorías",
            flex: 1,
            minWidth: 150,
            renderCell: (params) => {
                return (
                    <Select
                        multiple
                        value={params.value.map(categoria => categoria.id)}
                        style={{ width: '80%', height: '80%' }}
                    >
                        {params.value.map(categoria => (
                            <MenuItem key={categoria.id} value={categoria.id}>
                                {categoria.nombre}
                            </MenuItem>
                        ))}
                    </Select>
                );
            }
        },
        {
            field: "Acciones",
            headerName: "Acciones",
            filterable: false,
            disableExport: true,
            sortable: false,
            flex: 1,
            maxWidth: 175,
            renderCell: (params) => {
                return (
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <Button
                            variant="outlined"
                            color="primary"
                            onClick={() => handleOpenModal(params.row)}
                        >
                            Editar
                        </Button>
                        <IconButton
                            onClick={() => handleOpenDialogEliminar(params.row)}
                            sx={{ marginLeft: '10px' }}
                        >
                            <DeleteIcon sx={{ color: 'red' }} />
                        </IconButton>
                    </div>
                );
            }
        }
    ];

    return (
        <Grid
            container
            alignItems="center"
            justifyContent="center"
            flexDirection="column"
            style={{
                height: "100%",
                width: "100%",
            }}
            xs={12}
        >
            <Grid item xs={12}
                style={{ width: "100%", height: "100%" }}
            >
                <CustomDataGrid
                    rows={descuentos}
                    columns={columns}
                    initialState={{
                        sorting: {
                            sortModel: [{ field: 'id', sort: 'asc' }]
                        }
                    }}
                    pageSize={5}
                    disableRowSelectionOnClick
                    localeText={esES.components.MuiDataGrid.defaultProps.localeText}
                    slots={{ toolbar: CustomGridToolBar }}
                    style={{ flexGrow: 1 }}
                />
            </Grid>
            <Dialog
                open={openDialog.open}
                onClose={() => setOpenDialog({ open: false })}
            >
                <Grid
                    container
                    alignItems="center"
                    justifyContent="center"
                    flexDirection="column"
                    style={{ gap: "20px", textAlign: "center", padding: "50px", }}
                >
                    <Typography
                        variant="h6"
                        component="div"
                        style={{
                            textAlign: "center"
                        }}
                    >
                        ¿Estás seguro que deseas {openDialog.action} el descuento?
                    </Typography>
                    <Grid
                        item
                        container
                        xs={12}
                        justifyContent="center"
                        style={{ gap: "30px" }}
                    >
                        <Grid container item xs={12} justifyContent="space-between" style={{ gap: "20px" }}>
                            <Button variant="contained" color={"primary"}
                                onClick={() => handleCloseDialog(false)}
                            >
                                Cancelar
                            </Button>
                            <BotonLoading
                                loading={loading}
                                funcion={() => handleCloseDialog(true)}
                                color={openDialog.action === 'activar' ? "green" : "red"}
                                width="100px"
                            >
                                {openDialog.action === 'eliminar' ? "Eliminar" : (openDialog.action === 'activar' ? "Activar" : "Desactivar")}
                            </BotonLoading>
                        </Grid>
                    </Grid>
                </Grid>
            </Dialog>
            <ModalDescuentos
                open={openModal}
                onClose={handleOpenModal}
                descuentoSeleccionado={descuentoSeleccionado}
                descuentos={descuentos}
                setDescuentos={setDescuentos}
            />
        </Grid>
    );
}