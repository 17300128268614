import React, { useState, useContext, useEffect } from 'react';
import { SettingsContext } from '../../contexts/SettingsContext';
import { Box, IconButton, Menu, MenuItem, Skeleton } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';

const Categorias = (props) => {
  const { settings } = useContext(SettingsContext);
  const { categorias, categoriaSeleccionada, setCategoriaSeleccionada, loading } = props;
  const [anchorEl, setAnchorEl] = useState(null);

  const planes = ['1', '2'];

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleCategoriaClick = (categoria) => {
    setCategoriaSeleccionada(categoria);
    handleClose();
  };

  if (settings.id === 12) {
    return (
      <section style={{
        width: '100%',
        display: "flex",
        alignItems: "center",
        position: "relative"
      }}>
        {categoriaSeleccionada && (
          <Box
            sx={{
              fontSize: '1.2em',
              fontWeight: 'bold',
              color: settings?.color3,
              fontFamily: settings?.font_family,
              textAlign: 'center',
              flex: 1,
              marginLeft: '56px',
            }}
          >
            {categoriaSeleccionada.name}
          </Box>
        )}
        <IconButton
          onClick={handleClick}
          style={{
            backgroundColor: settings?.background_color3,
            color: settings?.color3,
            fontFamily: settings?.font_family,
            marginRight: '15px',
          }}
        >
          <MenuIcon />
        </IconButton>
        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleClose}
          PaperProps={{
            style: {
              backgroundColor: settings?.background_color2,
              maxHeight: '300px',
              width: '80%',
              borderRadius: '15px',
            }
          }}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
        >
          {categorias.map((category, index) => (
            <MenuItem
              key={index}
              onClick={() => handleCategoriaClick(category)}
              style={{
                backgroundColor: categoriaSeleccionada.id === category.id ? settings?.background_color3 : 'transparent',
                color: settings?.color3,
                fontFamily: settings?.font_family,
                transition: 'background-color 0.3s',
              }}
            >
              {category.name}
            </MenuItem>
          ))}
        </Menu>
      </section>
    );
  } else {
    return (
      <section style={{ width: '100%' }}>
        <div className="display-row justify-center align-items-center" style={{ overflowX: "auto", height: "70px", scrollbarWidth: "none" }}>
          <div style={{
            gap: "20px",
            overflowX: "auto",
            overflowY: "hidden",
            display: "flex",
            flexDirection: "row",
            paddingInline: "20px",
            scrollbarWidth: "none",
          }}>
            {loading ? (
              [...Array(3)].map((_, index) => (
                <Skeleton key={index} variant="button" width={120} height={40}
                  style={{ backgroundColor: settings?.background_color3, borderRadius: '2em' }}
                />
              ))
            ) : (
              categorias.map((category, index) => (
                <button
                  key={index}
                  className={`padding-2 bn3 ${categoriaSeleccionada === category.id ? 'selected' : ''}`}
                  style={{
                    backgroundColor: settings?.background_color3,
                    opacity: categoriaSeleccionada.id === category.id ? '1' : '0.5',
                    height: '40px',
                    fontSize: '1em',
                    paddingInline: '20px',
                    transition: 'opacity 0.5s',
                    cursor: 'pointer',
                    border: 'none',
                    color: settings?.color3,
                    fontFamily: settings?.font_family,
                    whiteSpace: 'nowrap',
                  }}
                  onClick={() => handleCategoriaClick(category)}
                >
                  {category.name}
                </button>
              ))
            )}
          </div>
        </div>
      </section>
    );
  }
};

export default Categorias;