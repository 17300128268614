import React, { useState, useContext, useEffect, useRef } from 'react';
import Categoria from "../../Components/Carta/Categoria";
import Categorias from "../../Components/Carta/Categorias";
import '../../Styles/App.css';
import { SettingsContext } from "../../contexts/SettingsContext";
import { Badge, BottomNavigation, BottomNavigationAction, Grid, IconButton } from '@mui/material';
import MenuBookIcon from '@mui/icons-material/MenuBook';
import WifiIcon from '@mui/icons-material/Wifi';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import Popover from '@material-ui/core/Popover';
import Typography from '@material-ui/core/Typography';
import WebFont from 'webfontloader';
import { useMediaQuery } from "@mui/material";
import LocalGroceryStoreIcon from '@mui/icons-material/LocalGroceryStore';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import Carrito from './components/Carrito';
import { useCategoria } from '../../Utils/UCategoria';
import Producto from '../../Components/Carta/Producto';
import Productos from './components/Productos';
import Marketplace from './components/marketplace/Marketplace';
import { host } from '../../Utils/Fetchs';


export default function CartaPage() {
    const [categoriaSeleccionada, setCategoriaSeleccionada] = useState(null);
    const [actualScreen, setActualScreen] = useState(0);
    const [open, setOpen] = useState(false);
    const anchorRef = useRef(null);
    const wifiLogoRef = useRef(null);
    const isMobile = useMediaQuery('(max-width:450px)');

    const handleWifiDialog = () => {
        setOpen(!open);
    };

    const { settings, wifi, getSettings, planes, getCantidadCarrito, getEmpresa, carritoDrawer, setCarritoDrawer } = useContext(SettingsContext)

    useEffect(() => {
        getSettings();
    }, [])

    useEffect(() => {
        const metaThemeColor = document.querySelector('meta[name=theme-color]');
        metaThemeColor.setAttribute('content', settings?.background_color2);
    }, [settings])

    const [categorias, setCategorias] = useState([]);
    const [loading, setLoading] = useState(true);

    const { getCategorias } = useCategoria();

    useEffect(() => {
        getCategorias(getEmpresa())
            .then(response => {
                setCategorias(response);
                if (response.length > 0) {
                    setCategoriaSeleccionada(response[0].id);
                    setCategoriaSeleccionada(response[0]);
                }
            })
            .catch(error => {
                console.error('Error al cargar categorías:', error);
            })
            .finally(() => {
                setLoading(false);
            });
    }, [getEmpresa()]);

    useEffect(() => {
        if (settings?.font_family) {
            WebFont.load({
                google: {
                    families: [settings?.font_family]
                }
            });
        }
    }, [settings?.font_family]);

    const handleCategoriaSeleccionada = (categoria) => {
        setCategoriaSeleccionada(categoria);
    };

    const secciones = [
        <div style={{
            marginTop: '80px',
            height: "100%",
            display: 'flex',
            flexDirection: 'column',
            gap: '20px',
        }}>
            <Categorias
                setCategoriaSeleccionada={handleCategoriaSeleccionada}
                loading={loading}
                categorias={categorias}
                categoriaSeleccionada={categoriaSeleccionada} />

            {categoriaSeleccionada && <Productos categoria={categoriaSeleccionada} color={settings?.background_color3} />}
        </div>
    ]

    if (settings?.tipo === '3') {
        return (
            <Marketplace />
        )
    }

    if (settings) return (
        <Grid container>
            <div style={{
                backgroundColor: settings?.background_color1,
                height: '100vh',
                overflow: 'hidden',
                paddingBottom: '56px',
            }} className="display-col widthmax">
                <div style={{
                    height: '0',
                    position: 'fixed',
                    zIndex: 1000,
                    alignItems: 'center',
                    justifyItems: 'center',
                    display: 'flex',
                    flexDirection: 'column',
                    width: '100%',
                }}>
                    <header className="align-items-center display-row"
                        style={{
                            backgroundColor: settings?.background_color2,
                            padding: '20px',
                            fontSize: isMobile ? '0.6em' : '0.8em',
                            width: '100%',
                            height: '75px',
                            textAlign: 'center',
                        }}>
                        {settings?.logo ?
                            <img src={`${host}/media/${settings?.logo}`} alt="logo" style={{
                                width: "100%",
                                maxWidth: '150px',
                                margin: 'auto',
                                height: '100%',
                            }} />
                            :
                            <h1 style={{ margin: 'auto', color: settings?.color1, fontFamily: settings?.font_family }}>{settings?.nombre}</h1>
                        }
                    </header>
                    <SwipeableDrawer
                        anchor={'bottom'}
                        open={carritoDrawer}
                        onClose={() => setCarritoDrawer(false)}
                        onOpen={() => setCarritoDrawer(true)}
                    >
                        <Carrito />
                    </SwipeableDrawer>
                </div>
                {secciones[actualScreen]}
            </div>
            {((wifi.red && wifi.clave) || planes.includes('1')) &&
                <BottomNavigation
                    showLabels
                    sx={{
                        backgroundColor: settings?.background_color2,
                        position: 'fixed',
                        bottom: 0,
                        width: '100%',
                        zIndex: 1,
                        '& .Mui-selected .MuiSvgIcon-root': {
                            color: settings?.background_color3,
                        },

                        '& .Mui-selected': {
                            color: settings?.background_color3,
                        }

                    }}
                    value={actualScreen}
                    onChange={(event, newValue) => {
                        if (newValue === 1) {
                            setOpen(true);
                            return;
                        }
                    }}
                >
                    <BottomNavigationAction
                        icon={
                            <>
                                <MenuBookIcon style={{ color: settings?.color2 }} />
                                <Typography style={{ color: settings?.color2, fontFamily: settings?.font_family, fontSize: '0.7rem' }}>
                                    {settings?.tipo === '1' ? 'Menú' : 'Catalogo'}
                                </Typography>
                            </>
                        }
                    />
                    {wifi.red && wifi.clave &&
                        <BottomNavigationAction
                            label="Wi Fi"
                            ref={wifiLogoRef}
                            sx={{
                                color: settings?.color2,
                                fontFamily: settings?.font_family
                            }}
                            icon={
                                <WifiIcon />
                            }
                        />}
                    {planes.includes('1') &&
                        <BottomNavigationAction
                            onClick={() => setCarritoDrawer(true)}
                            label="Carrito"
                            sx={{
                                color: settings?.color2,
                                fontFamily: settings?.font_family
                            }}
                            icon={
                                <Badge badgeContent={getCantidadCarrito()} sx={{
                                    '& .MuiBadge-badge': {
                                        backgroundColor: settings?.background_color3,
                                        color: settings?.color2,
                                    }

                                }}>
                                    <LocalGroceryStoreIcon />
                                </Badge>
                            }
                            ref={anchorRef}
                        />}
                </BottomNavigation>
            }

            <Popover
                open={open}
                anchorEl={wifiLogoRef.current}
                onClose={handleWifiDialog}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                PaperProps={{
                    style: {
                        height: '200px',
                        width: '200px',
                        display: 'flex',
                        alignItems: 'center',
                        flexDirection: 'column',
                        clipPath: 'polygon(0% 0%, 100% 0%, 100% 80%, 50% 100%, 0% 80%)',
                        position: 'relative',
                        overflow: 'visible',
                        borderRadius: '30px 30px 90px 90px',
                    },
                }}
            >
                <Typography style={{
                    marginBottom: '15px', marginTop: '15px',
                    color: 'black', fontSize: '1.2em', fontFamily: settings?.font_family
                }}>
                    Red: {wifi.red}
                </Typography>
                <Typography style={{ color: 'black', fontSize: '1.2em', fontFamily: settings?.font_family }}>
                    Clave: {wifi.clave}
                </Typography>
                <IconButton
                    style={{
                        position: 'absolute',
                        bottom: '30px',
                        color: 'black',
                        transition: 'transform 0.4s ease',
                    }}
                    onClick={(e) => {
                        navigator.clipboard.writeText(wifi.clave);
                        const target = e.currentTarget;
                        if (target) {
                            target.style.transform = 'scale(1.5)';
                            setTimeout(() => {
                                target.style.transform = 'scale(1)';
                            }, 200);
                        }
                    }}
                >
                    <ContentCopyIcon style={{ fontSize: '1.5em' }} />
                </IconButton>
            </Popover>
        </Grid >
    );
}
