import { Grid } from "@mui/material";
import Producto from "./producto/Producto";
import { useContext, useEffect, useState } from "react";
import { SettingsContext } from "../../../contexts/SettingsContext";

export default function Productos(props) {
    const { categoria, color } = props;
    const { descuentos } = useContext(SettingsContext);

    const [descuentosCategoria, setDescuentosCategoria] = useState([]);

    useEffect(() => {
        let newDescuentosCategoria = []

        descuentos.map(d => {
            if (d.categorias.find(c => c.id === categoria.id)) newDescuentosCategoria.push(d);
        });
        console.log(newDescuentosCategoria);

        setDescuentosCategoria(newDescuentosCategoria || []);
    }, [categoria, descuentos]);

    return (
        <Grid container spacing={2} sx={{
            paddingX: 2,
            height: "100%",
            paddingBottom: "200px",
            overflowY: "auto",
            overflowX: "hidden",
            '&::-webkit-scrollbar': {
                display: 'none'
            },
            scrollbarWidth: 'none',
        }}>
            <Grid item xs={12} sx={{
                fontSize: '1em',
                fontWeight: 'bold',
                color: color,
                fontFamily: 'font_family',
                textAlign: 'center',
            }}>
                {descuentosCategoria?.map(d => d.descripcion).join(' - ')}
            </Grid>
            {categoria.products.map((producto, index) => (
                <Grid item key={producto.id} xs={6} sm={4} md={3} lg={3} xl={2}
                    className={index % 2 === 0 ? 'slide-left' : 'slide-right'}
                    sx={{
                        height: 300,
                    }}
                >
                    <Producto producto={{ ...producto, categoria: categoria.id }} index={index} tipo={categoria.tipoProducto} descuentos={descuentosCategoria} />
                </Grid>
            ))}
        </Grid>
    )
}